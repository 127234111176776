import { FC } from 'react';
import IconProps from './IconProps';

const ProductIcon: FC<IconProps> = (props) => {
  const { onClick, className, accentColor = '#C5A76B', gradient } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  if (gradient) {
    return (
      <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7259 9.08846C12.6608 9.11086 12.5912 9.11086 12.5261 9.08846L1.80839 5.40425C1.66856 5.35621 1.57336 5.21378 1.57336 5.05263C1.57336 4.89148 1.66856 4.74905 1.80839 4.70101L12.5261 1.01677C12.5912 0.994409 12.6608 0.994409 12.7259 1.01677L23.4436 4.70101C23.5834 4.74905 23.6786 4.89148 23.6786 5.05263C23.6786 5.21378 23.5834 5.35621 23.4436 5.40425L12.7259 9.08846ZM20.6213 5.60246C21.1445 5.42261 21.1445 4.68266 20.6213 4.50281L13.004 1.88432C12.759 1.80011 12.493 1.80011 12.248 1.88432L4.63066 4.50281C4.10746 4.68266 4.10746 5.42261 4.63065 5.60246L12.248 8.22094C12.493 8.30515 12.759 8.30515 13.004 8.22094L20.6213 5.60246Z"
          fill="url(#paint0_linear_4183_66106)"
          stroke="url(#paint1_linear_4183_66106)"
          strokeWidth="1.16281"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3527 4.68433C23.1732 4.68433 23.0276 4.84931 23.0276 5.05275V11.3685C23.0276 11.572 23.1732 11.737 23.3527 11.737C23.5322 11.737 23.6778 11.572 23.6778 11.3685V5.05275C23.6778 4.84931 23.5322 4.68433 23.3527 4.68433ZM12.2134 8.36854C12.0339 8.36854 11.8883 8.53351 11.8883 8.73696V22.0001C11.8883 22.1173 11.9375 22.2275 12.0208 22.2969C12.1042 22.3664 12.2116 22.3867 12.3103 22.3517L15.5611 21.2004C15.7325 21.1398 15.828 20.9331 15.7744 20.7389C15.7209 20.5447 15.5385 20.4365 15.3672 20.4972L13.314 21.2243C12.9356 21.3583 12.5385 21.0777 12.5385 20.6763V8.73696C12.5385 8.53351 12.3929 8.36854 12.2134 8.36854Z"
          fill="url(#paint2_linear_4183_66106)"
          stroke="url(#paint3_linear_4183_66106)"
          strokeWidth="1.16281"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.89783 4.68433C2.07737 4.68433 2.22291 4.84931 2.22291 5.05275V17.2233C2.22291 17.7158 2.53323 18.1549 2.99752 18.3194L11.1997 21.2243C11.5781 21.3583 11.9752 21.0777 11.9752 20.6763V8.73696C11.9752 8.53351 12.1208 8.36854 12.3003 8.36854C12.4798 8.36854 12.6254 8.53351 12.6254 8.73696V22.0001C12.6254 22.1173 12.5762 22.2275 12.4929 22.2969C12.4095 22.3664 12.3021 22.3867 12.2034 22.3517L1.80087 18.6675C1.66515 18.6195 1.57275 18.477 1.57275 18.3159V5.05275C1.57275 4.84931 1.7183 4.68433 1.89783 4.68433Z"
          fill="url(#paint4_linear_4183_66106)"
          stroke="url(#paint5_linear_4183_66106)"
          strokeWidth="1.16281"
        />
        <path
          d="M8.23907 2.91047C8.09824 2.85568 7.94723 2.94892 7.90179 3.11875C7.8564 3.28857 7.93373 3.47067 8.07455 3.52547L17.4582 7.31583L17.9941 7.08054C17.9941 6.94032 17.919 6.81612 17.8084 6.77308L8.23907 2.91047Z"
          fill="url(#paint6_linear_4183_66106)"
          stroke="url(#paint7_linear_4183_66106)"
          strokeWidth="1.16281"
        />
        <path
          d="M24.866 17.1053C24.866 19.3304 23.0621 21.1343 20.8369 21.1343C18.6118 21.1343 16.8079 19.3304 16.8079 17.1053C16.8079 14.8801 18.6118 13.0762 20.8369 13.0762C23.0621 13.0762 24.866 14.8801 24.866 17.1053Z"
          stroke="url(#paint8_linear_4183_66106)"
          strokeWidth="1.41559"
        />
        <path
          d="M21.1714 18.3493V17.4403H22.0803C22.1692 17.4403 22.2544 17.405 22.3172 17.3422C22.38 17.2794 22.4153 17.1942 22.4153 17.1053C22.4153 17.0165 22.38 16.9313 22.3172 16.8684C22.2544 16.8056 22.1692 16.7703 22.0803 16.7703H21.1714V15.8614C21.1714 15.7725 21.1361 15.6873 21.0733 15.6245C21.0104 15.5617 20.9252 15.5264 20.8364 15.5264C20.7475 15.5264 20.6623 15.5617 20.5995 15.6245C20.5367 15.6873 20.5014 15.7725 20.5014 15.8614V16.7703H19.5924C19.5036 16.7703 19.4184 16.8056 19.3556 16.8684C19.2927 16.9313 19.2574 17.0165 19.2574 17.1053C19.2574 17.1942 19.2927 17.2794 19.3556 17.3422C19.4184 17.405 19.5036 17.4403 19.5924 17.4403H20.5014V18.3493C20.5014 18.4381 20.5367 18.5233 20.5995 18.5861C20.6623 18.649 20.7476 18.6843 20.8364 18.6843C20.9252 18.6843 21.0104 18.649 21.0733 18.5861C21.1361 18.5233 21.1714 18.4381 21.1714 18.3493H21.1714Z"
          fill="url(#paint9_linear_4183_66106)"
          stroke="url(#paint10_linear_4183_66106)"
          strokeWidth="0.581403"
        />
        <defs>
          <linearGradient id="paint0_linear_4183_66106" x1="1.57336" y1="3.47489" x2="12.6553" y2="16.3949" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint1_linear_4183_66106" x1="1.57336" y1="3.47489" x2="12.6553" y2="16.3949" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint2_linear_4183_66106" x1="11.8883" y1="10.0841" x2="24.7848" y2="13.7594" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint3_linear_4183_66106" x1="11.8883" y1="10.0841" x2="24.7848" y2="13.7594" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint4_linear_4183_66106" x1="1.57275" y1="10.0841" x2="13.7742" y2="13.344" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint5_linear_4183_66106" x1="1.57275" y1="10.0841" x2="13.7742" y2="13.344" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint6_linear_4183_66106" x1="7.88879" y1="4.24471" x2="14.0032" y2="10.2191" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint7_linear_4183_66106" x1="7.88879" y1="4.24471" x2="14.0032" y2="10.2191" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint8_linear_4183_66106" x1="16.1001" y1="15.2611" x2="25.5738" y2="19.311" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint9_linear_4183_66106" x1="19.2574" y1="16.4906" x2="22.4153" y2="17.8405" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint10_linear_4183_66106" x1="19.2574" y1="16.4906" x2="22.4153" y2="17.8405" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0597 9.19849C11.9947 9.22089 11.925 9.22089 11.86 9.19849L1.14225 5.51428C1.00243 5.46624 0.907227 5.32381 0.907227 5.16266C0.907227 5.00152 1.00243 4.85908 1.14225 4.81104L11.86 1.1268C11.925 1.10444 11.9947 1.10444 12.0597 1.1268L22.7774 4.81104C22.9173 4.85908 23.0125 5.00152 23.0125 5.16266C23.0125 5.32381 22.9173 5.46624 22.7774 5.51428L12.0597 9.19849ZM19.9552 5.71249C20.4784 5.53264 20.4784 4.79269 19.9552 4.61284L12.3379 1.99435C12.0929 1.91014 11.8268 1.91014 11.5819 1.99435L3.96452 4.61284C3.44132 4.79269 3.44132 5.53264 3.96452 5.71249L11.5819 8.33097C11.8268 8.41518 12.0929 8.41518 12.3379 8.33097L19.9552 5.71249Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.16281"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6861 4.79427C22.5066 4.79427 22.361 4.95924 22.361 5.16269V11.4785C22.361 11.6819 22.5066 11.8469 22.6861 11.8469C22.8656 11.8469 23.0112 11.6819 23.0112 11.4785V5.16269C23.0112 4.95924 22.8656 4.79427 22.6861 4.79427ZM11.5468 8.47848C11.3672 8.47848 11.2217 8.64345 11.2217 8.8469V22.1101C11.2217 22.2273 11.2709 22.3374 11.3542 22.4069C11.4375 22.4763 11.545 22.4967 11.6437 22.4617L14.8945 21.3104C15.0659 21.2497 15.1614 21.043 15.1078 20.8489C15.0543 20.6546 14.8719 20.5464 14.7006 20.6071L12.6473 21.3343C12.269 21.4683 11.8718 21.1876 11.8718 20.7862V8.8469C11.8718 8.64345 11.7263 8.47848 11.5468 8.47848Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.16281"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.23133 4.79427C1.41086 4.79427 1.5564 4.95924 1.5564 5.16269V17.3332C1.5564 17.8258 1.86673 18.2649 2.33101 18.4293L10.5332 21.3343C10.9116 21.4683 11.3087 21.1876 11.3087 20.7862V8.8469C11.3087 8.64345 11.4543 8.47848 11.6338 8.47848C11.8133 8.47848 11.9589 8.64345 11.9589 8.8469V22.1101C11.9589 22.2273 11.9097 22.3374 11.8264 22.4069C11.743 22.4763 11.6356 22.4967 11.5369 22.4617L1.13436 18.7775C0.99865 18.7294 0.90625 18.587 0.90625 18.4258V5.16269C0.90625 4.95924 1.05179 4.79427 1.23133 4.79427Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.16281"
      />
      <path
        d="M7.57293 3.02046C7.4321 2.96566 7.28109 3.05891 7.23565 3.22873C7.19026 3.39855 7.26759 3.58065 7.40842 3.63545L16.792 7.42581L17.3279 7.19053C17.3279 7.0503 17.2529 6.9261 17.1422 6.88307L7.57293 3.02046Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.16281"
      />
      <path
        d="M24.1995 17.2153C24.1995 19.4405 22.3956 21.2443 20.1704 21.2443C17.9453 21.2443 16.1414 19.4405 16.1414 17.2153C16.1414 14.9901 17.9453 13.1862 20.1704 13.1862C22.3956 13.1862 24.1995 14.9901 24.1995 17.2153Z"
        stroke="currentColor"
        strokeWidth="1.41559"
      />
      <path
        d="M20.5048 18.4592V17.5503H21.4137C21.5026 17.5503 21.5878 17.515 21.6506 17.4522C21.7134 17.3893 21.7487 17.3041 21.7487 17.2153C21.7487 17.1264 21.7134 17.0412 21.6506 16.9784C21.5878 16.9156 21.5026 16.8803 21.4137 16.8803H20.5048V15.9713C20.5048 15.8825 20.4695 15.7973 20.4066 15.7345C20.3438 15.6716 20.2586 15.6363 20.1698 15.6363C20.0809 15.6363 19.9957 15.6716 19.9329 15.7345C19.8701 15.7973 19.8348 15.8825 19.8348 15.9713V16.8803H18.9258C18.837 16.8803 18.7518 16.9156 18.6889 16.9784C18.6261 17.0412 18.5908 17.1264 18.5908 17.2153C18.5908 17.3041 18.6261 17.3893 18.6889 17.4522C18.7518 17.515 18.837 17.5503 18.9258 17.5503H19.8348V18.4592C19.8348 18.5481 19.8701 18.6333 19.9329 18.6961C19.9957 18.7589 20.0809 18.7942 20.1698 18.7942C20.2586 18.7942 20.3438 18.7589 20.4066 18.6961C20.4695 18.6333 20.5048 18.5481 20.5048 18.4592H20.5048Z"
        fill={accentColor}
        stroke={accentColor}
        strokeWidth="0.581403"
      />
    </svg>
  );
};

export default ProductIcon;
